[
  {
    "name": "cheqd",
    "ownerAddress": "cheqdvaloper13a4s92v0m0y83s4p7uqstn4ceqfkxjgu5sdlwu"
  },
  {
    "name": "chihuahua",
    "ownerAddress": "chihuahuavaloper1gh75vw72lund9vuf6743lyez8lj0wttcrx49na"
  },
  {
    "name": "injective",
    "ownerAddress": "injvaloper1n2q9ke92yjyc3uf6084d9jssuu9urj608cc2hp"
  },
  {
    "name": "juno",
    "ownerAddress": "junovaloper1sfw4nl8fq9u3987vand6yjwdhvgw5phewpvg3l"
  },
  {
    "name": "kichain",
    "ownerAddress": "kivaloper1tkeg06v4s88ml2f555qfev6c9s56fawys3cd8n"
  },
  {
    "name": "nois",
    "ownerAddress": "noisvaloper1zhqh6mv5qf0jad25ethrgsljmfmwwrajvykp5y"
  },
  {
    "name": "oraichain",
    "ownerAddress": "oraivaloper1d2ths86a84rxpp7s9j64qgrypntw2pn7lndfgs"
  },
  {
    "name": "stargaze",
    "ownerAddress": "starsvaloper1vaj7mt7kcrdxvfcl2ye7c2qwuxxmj9a7w326hx"
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper1nuh2h60wlvzvk58xll3d8gz2wpqjt6gwzet02z"
  }
]
